@import "../../variables";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.page {
    &.overflow-y-scroll {
        overflow-y: scroll;
    }
}

.homepage-timelines-block {
    gap: 40px;
    padding: 48px 0;
    background-color: $white;

    @include media-breakpoint-up(md) {
        gap: 48px;
        padding: 80px 0;
    }

    .homepage-timelines-block-heading {
        margin: 0;
        padding: 0 24px;
        max-width: 100%;

        @include media-breakpoint-up(md) {
            max-width: 1400px;
            padding: 0;
            margin: 0 80px;
        }
    }

    .homepage-timelines-block-container {
        &.scrollbar-margin {
            margin-right: 20px;
        }

        .last-item-wrapper {
            padding: 0 12px;
        }

        > div {
            &:first-child {
                margin-left: 24px;
            }

            &:last-child {
                margin-right: 24px;
            }
        }
    }

    .homepage-timelines-block-button {
        height: 44px;
        padding: 10px 16px;
        color: $white;
        font-family: $font-qualion;
        font-size: 18px;
        font-weight: 700;
        line-height: 26px;
        border-radius: 4px;
        background-color: $dark-green;
        text-decoration: none !important;

        &:hover {
            background-color: $light-green-tertiary;
        }

        @include media-breakpoint-up(md) {
            height: 56px;
            padding: 16px 24px;
        }
    }

    .homepage-timelines-block-item {
        gap: 8px;
        width: 224px;

        @include media-breakpoint-up(md) {
            width: 280px;
        }

        .homepage-timelines-block-item-time {
            color: $neutral-80;
            font-family: $font-qualion;
            font-size: 18px;
            font-weight: 400;
            line-height: 26px;
        }

        .homepage-timelines-block-item-icon {
            width: 32px;
            height: 32px;
        }

        .homepage-timelines-block-item-wrapper {
            padding: 24px 16px;
            height: 120px;

            @include media-breakpoint-up(md) {
                height: 138px;
            }

            &.item-1 {
                background-color: $gold;
                border-radius: 16px 0px 0px 16px;
            }

            &.item-2 {
                background-color: $light-green-secondary;
            }

            &.item-3 {
                background-color: $blue-puppy;
            }

            &.item-4 {
                background-color: $peach;
            }

            &.item-5 {
                background-color: $pink;
            }

            &.item-6 {
                background-color: $egg;
            }

            &.item-7 {
                background-color: $violet;
            }

            &.item-8 {
                background-color: $salmon;
            }

            &.last-item {
                border-radius: 0px 16px 16px 0px;
            }
        }

        .homepage-timelines-block-item-benefit {
            color: $neutral-80;
            font-family: $font-qualion;
            font-size: 24px;
            font-weight: 700;
            line-height: 36px;

            @include media-breakpoint-up(md) {
                font-size: 28px;
            }
        }

        .homepage-timelines-block-item-description {
            color: $neutral-80;
            font-family: $font-qualion;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;

            @include media-breakpoint-up(md) {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }

    .homepage-timelines-block-scrollbar-wrapper {
        position: relative;
        background-color: $neutral-5;
        width: 224px;
        height: 12px;
        border-radius: 16px;

        .homepage-timelines-block-scrollbar {
            position: absolute;
            background-color: $dark-green;
            width: 80px;
            height: 12px;
            border-radius: 16px;
        }
    }

    .homepage-timelines-block-wrapper {
        overflow: scroll;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .homepage-timelines-block-scrollable {
        overflow-x: scroll;
        padding-left: 24px;

        @include media-breakpoint-up(md) {
            padding-left: 80px;
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .homepage-timelines-block-container-v2 {
        gap: 24px;

        .homepage-timelines-block-item-image-wrapper {
            border-radius: 20px 20px 0px 0px;
            overflow: hidden;
            height: 170px;
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
        }

        .homepage-timelines-block-item-image {
            width: 120px;
            height: auto;
        }

        .homepage-timelines-block-item-wrapper {
            background-color: $neutral-10 !important;
            border-radius: 0 0 20px 20px !important;
            padding: 20px;
            min-height: 208px;
        }

        .homepage-timelines-block-item {
            gap: 0;
        }

        .homepage-timelines-block-item-benefit {
            font-size: 18px;
            line-height: 26px;
            margin: 0 0 14px;
            color: $black;
        }

        .homepage-timelines-block-item-description  {
            font-size: 16px;
            line-height: 24px;
            margin: 0;
            color: $neutral-70;
        }
    }
}
